<template>
  <div>
    <!--Login Section-->
    <section class="login-section">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="column col-md-6 offset-md-3 col-sm-12 col-xs-12">
            <h2>إستعادة كلمة المرور</h2>

            <!-- Login Form -->
            <div class="login-form">
              <!--Login Form-->
              <form @submit.prevent="resetEmail">
                <div class="form-group">
                  <label>أدخل الإيميل </label>
                  <input v-model="email" type="text" placeholder="الإيميل" />
                </div>

                <div class="clearfix">
                  <div class="pull-right">
                    <div class="form-group no-margin">
                      <button
                        class="theme-btn btn-style-one"
                        type="submit"
                        name="submit-form"
                      >
                        إرسال
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!--End Login Form -->
          </div>
        </div>
      </div>
    </section>
    <!--End Login Section-->
  </div>
</template>

<script>
export default {
  name: "reset-password",
  data() {
    return {
      email: null,
    };
  },
  mounted() {
    this.$axios.get("../sanctum/csrf-cookie");

    this.email = this.$route.query.email;
  },
  methods: {
    resetEmail() {
      let $this = this;
      this.$axios.post("/send-password-reset-email", { email: this.email });
    },
  },
};
</script>

<style scoped>
.rtl .login-form .form-group {
  text-align: right;
}

@media (min-width: 768px) {
  .offset-md-3 {
    margin-right: 25%;
  }
}
</style>